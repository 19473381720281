<script>

import store from "./store/index.js";
import {setCurrentAnalyticsStep} from "../../helpers/tracking_helper";

export default {
  name: 'step-4',

  props: ['formValues'],

  data() {
    return {
      formFields: {
        password: this.formValues.password,
      },
      password_confirmation: '',
      errorMessage: '',
      formErrorsArray: []
    }
  },
  methods: {
    onSubmit() {
      this.errorMessage = '';
      this.formErrorsArray = this.getFormErrors();

      if (this.formErrorsArray.length > 0) {
        return;
      }

      if (this.formFields.password !== this.password_confirmation) {
        this.errorMessage = 'Velden komen niet overeen';
        return;
      }
      setCurrentAnalyticsStep('step_four');
      store.commit('saveFormValues', {formFields: this.formFields})
      store.dispatch('completeSubscription');
    },
    getFormErrors() {
      const errors = [];
      if (this.formFields.password == '') {
        errors.push('password');
      } else {
        const regex = new RegExp('((?=.*\\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*');
        if (this.formFields.password.length < 8 || !regex.test(this.formFields.password)) {
          errors.push('password');
          this.errorMessage = 'Wachtwoord voldoet niet aan de vereisten';
        }
      }
      if (this.password_confirmation == '') {
        errors.push('password_confirmation');
      }
      return errors;
    },
    previousStep() {
      store.commit('previousStep');
    }
  },
}
</script>

<template>
  <form @submit.prevent="onSubmit" class="step-4" id="bezorgformulier_step_4" name="Bezorgformulier stap 4">
    <div class="row">
      <div class="col-12">
        <h3 class="df-title">Meld je <span class="m-title-highlight">hier</span> aan</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-4">
        <div class="m-text">
          <p>
            Maak hieronder een wachtwoord aan voor je account. Na het versturen van het formulier, ontvang je een activatie e-mail. Download na activatie vervolgens de Mijn Spotta bezorgersapp en maak je registratie compleet. In de app vind je alle belangrijke informatie over het bezorgen bij Spotta en kun je een bezorgwijk selecteren.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <input v-model="formFields.password" placeholder="Wachtwoord"
               class="form-control" type="password" autocomplete="off" :class="{'is-invalid': formErrorsArray.indexOf('password') !== -1}">
        <small>
          Wachtwoord moet bestaan uit <strong>Hoofdletters, kleine letters, nummers, speciale tekens en minimaal 8
          karakters
        </strong>
        </small>
      </div>
      <div class="col-md-12 mb-4">
        <input v-model="password_confirmation" placeholder="Wachtwoord (nogmaals)" autocomplete="off" :class="{'is-invalid': formErrorsArray.indexOf('password_confirmation') !== -1}"
               class="form-control" type="password">
      </div>
    </div>
    <div class="row" v-if="errorMessage.length">
      <div class="col-12 mb-4">
        <small class="text-danger">{{ errorMessage }}</small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-4">
        <button type="button" class="m-button btn button default-btn" v-on:click="previousStep"><i class="fa fa-chevron-left"></i> Vorige</button>
        <button type="submit" class="m-button btn button deliver-btn float-right">Verstuur <i class="fa fa-chevron-right invisible"></i></button>
      </div>
    </div>
    <div class="col-md-12">
      <div class="m-text">
        <p>&nbsp;</p>
      </div>
    </div>
  </form>
</template>

